import { Button, TableService, Tabs, Tooltip } from '@gmini/ui-kit'
import { useStore } from 'effector-react'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'

import {
  ErrorMessage,
  fileValidationRules,
  isImage,
  maxCountErrorMessage,
  useFilesValidation,
} from '@gmini/helpers'

import {
  Gallery,
  ShowAll,
  smallPencil,
  TextFieldMultilineWithPreview,
} from '@gmini/components'

import * as api from '@gmini/ism-api-sdk'

import { createStore } from 'effector'

import moment from 'moment'

import { Comments } from '../../organisms/Comments'
import { EditIssuePopupHistoryChanges } from '../../organisms/EditIssuePopupHistoryChanges'
import { IssueOfficialReply } from '../../organisms/IssueOfficialReply'

import { changeStatus, fetchIssueHistory, updateIssue } from '../../issue.store'

import {
  deleteFile,
  downloadArchivedFiles,
  downloadFile,
  fileLinkById$,
  fileList$,
  uploadFiles,
} from '../../file.store'

import { AttachFile, SelectView } from '../../atoms'

import { editIssuePending$ } from '../../organisms/EditIssuePopup/editIssuePending'

import {
  AttachmentTable,
  getAttachmentListColumnOrderFromStorage,
  getAttachmentListSortFromStorage,
  setAttachmenListColumnsToStorage,
} from '../../organisms'

import { allUserList$ } from '../../user.store'

import {
  ActivityWrapper,
  AttachIconsWrapper,
  AttachmentCount,
  AttachmentFieldName,
  AttachmentTitlePanel,
  DownloadButton,
  FieldName,
  ShowAllWrapper,
  SimpleScrollbar,
} from './EditIssue.styled'

import { TabIndex } from './tabIndex'
import { MainContentProps } from './types'
import { attachmentTableService } from './model'

const setSelectViewTypeToStorage = (isCompact: boolean) => {
  localStorage.setItem(
    'attachmentListSelectViewType',
    JSON.stringify(isCompact),
  )
}

const getSelectViewTypeFromStorage = (): boolean | undefined => {
  const data = localStorage.getItem('attachmentListSelectViewType')
  if (typeof data === 'string') {
    return JSON.parse(data)
  }
}

const copiedDelay = 5000

const initialCountFiles = 3

const descriptionFieldStyles = {
  fontSize: '14px',
  lineHeight: '20px',
  letterSpacing: '0.15px',
}

export const MainContent = ({
  userInfo,
  issue,
  onUpdateIssueHandler,
  renderFileViewer,
  renderFileValidationErrorsPopup,
}: MainContentProps) => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const issueAnswer = issue?.answer || null
  const fileList = useStore(fileList$)
  const allUserList = useStore(allUserList$)
  const issuePending = useStore(editIssuePending$)
  const fileLinkById = useStore(fileLinkById$)
  const [isCompact, setIsCompact] = useState(
    getSelectViewTypeFromStorage() || false,
  )
  const [columns, setColumns] = useState(
    getAttachmentListColumnOrderFromStorage,
  )

  const [copied, setCopied] = useState(false)
  const [tabIndex, setTabIndex] = useState(TabIndex.comment)

  const imgList = fileList?.filter(isImage)
  const imgLinkList = imgList?.map(({ id }) => fileLinkById[id]).filter(Boolean)
  const [isHidden, setIsHidden] = useState(false)

  const checked: Record<number, boolean> = useStore(
    attachmentTableService
      ? attachmentTableService.checked$
      : createStore<TableService.Checked>({}),
  )
  const checkedCount = useStore(
    attachmentTableService
      ? attachmentTableService.checkedCount$
      : createStore(0),
  )
  const allChecked = checkedCount === fileList?.length

  const onCheckedFile = (file: api.Issue.File) => {
    attachmentTableService?.updateChecked({ [file.id]: !checked[file.id] })
  }

  const onCheckAll = () => {
    if (allChecked) {
      attachmentTableService?.resetChecked()
      return
    }

    const entries = (fileList || []).map(row => [row.id, true])
    attachmentTableService?.setChecked(Object.fromEntries(entries))
  }

  const { validateFiles, clearFileErrors } = useFilesValidation({
    rules: fileValidationRules,
    onError: (errors: ErrorMessage[]) =>
      renderFileValidationErrorsPopup?.({
        open: true,
        errors,
        onClose: () =>
          renderFileValidationErrorsPopup?.({
            open: false,
          }),
      }),
  })

  useEffect(() => {
    fetchIssueHistory({
      issueId: issue.id,
      issueVersion: issue.version,
    })
  }, [issue.version, issue.id])

  useEffect(() => {
    setAttachmenListColumnsToStorage(columns)
  }, [columns])

  useEffect(() => {
    if (issue.id && issue.version && tabIndex === TabIndex.activity) {
      fetchIssueHistory({
        issueId: issue.id,
        issueVersion: issue.version,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabIndex])

  useEffect(() => {
    if (copied) {
      const timer = setTimeout(() => setCopied(false), copiedDelay)

      return () => clearTimeout(timer)
    }
  }, [copied])

  const [sort, setSort] = useState(getAttachmentListSortFromStorage())

  const onUpdateIssue = useCallback(
    async (updateParams: Partial<api.Issue.UpdateParams>) => {
      const nextIssue = await updateIssue({
        id: issue.id,
        version: issue.version,
        ...updateParams,
      })

      onUpdateIssueHandler?.(issue, nextIssue)

      return issue
    },
    [issue, onUpdateIssueHandler],
  )

  const onOpenViewer = useCallback(
    (file: api.Issue.File) => {
      if (!isImage(file)) {
        return
      }

      const imgIdx = imgList?.findIndex(img => img.name === file.name) || 0
      renderFileViewer?.({
        linkList: imgLinkList!,
        open: true,
        setOpen: () =>
          renderFileViewer?.({
            open: false,
          }),
        startIndex: imgIdx,
        info: {
          name: file.name,
          author:
            allUserList.find(user => user.id === file.createdBy)?.name || '',
          uploadAt: moment(file.uploadAt).format('DD.MM.YYYY'),
        },
        onDownload: () => downloadFile({ fileId: file.id }),
      })
    },
    [imgList, renderFileViewer, allUserList, imgLinkList],
  )

  const getImageLink = (id: number) =>
    imgList?.some(img => img.id === id) ? fileLinkById[id] : null

  const onUploadFile = async (event: ChangeEvent<HTMLInputElement>) => {
    onUpdateIssueHandler?.(issue, issue)

    const files = event.target?.files
    if (!files) {
      return
    }

    clearFileErrors()

    const allowFiles = validateFiles(Object.values(files), fileList?.length)

    if (allowFiles.length) {
      await uploadFiles({ issue, files: allowFiles })
    }
  }

  const isMaxCountFiles = Number(fileList?.length) >= 20

  const onDeleteFile = (file: api.Issue.File) => {
    deleteFile({
      fileId: file.id,
      issueId: issue.id,
      issueVersion: issue.version,
    })

    onUpdateIssueHandler?.(issue, issue)
  }

  const onDownloadFile = (file: api.Issue.File) => {
    downloadFile({ fileId: file.id })
  }

  const onAcceptOfficialReply = async () => {
    const nextIssue = await changeStatus({
      issueId: issue.id,
      issueVersion: issue.version,
      status: api.IssueStatus.Enum.CLOSED,
    })

    onUpdateIssueHandler?.(issue, nextIssue)
  }
  const onRejectOfficialReply = async () => {
    const nextIssue = await changeStatus({
      issueId: issue.id,
      issueVersion: issue.version,
      status: api.IssueStatus.Enum.IN_PROCESS,
      issueAnswerStatus: api.IssueAnswer.StatusEnum.DECLINED,
    })

    onUpdateIssueHandler?.(issue, nextIssue)
  }
  const onDeleteOfficialReply = async () => {
    const nextIssue = await changeStatus({
      issueId: issue.id,
      issueVersion: issue.version,
      status: api.IssueStatus.Enum.IN_PROCESS,
      issueAnswerStatus: api.IssueAnswer.StatusEnum.DELETED,
    })
    onUpdateIssueHandler?.(issue, nextIssue)
  }

  const onDownloadFiles = useCallback(() => {
    const selectedFiles = (fileList || []).filter(file => checked[file.id])
    if (selectedFiles.length === 1) {
      downloadFile({ fileId: selectedFiles[0].id })
    }

    if (selectedFiles.length > 1) {
      downloadArchivedFiles({
        issueId: issue.id,
        issueVersion: issue.version,
        fileIds: selectedFiles.map(file => file.id),
        timezone,
      })
    }
  }, [checked, fileList, issue, timezone])

  const isIssueClosed = issue.status === api.IssueStatus.Enum.CLOSED

  return (
    <>
      <div>
        <FieldName>Описание</FieldName>

        <TextFieldMultilineWithPreview
          value={issue.description || ''}
          onSubmit={(value: string) => onUpdateIssue({ description: value })}
          viewMaxLength={1024}
          styleText={{
            fontWeight: 400,
            ...descriptionFieldStyles,
          }}
          disabled={issuePending || isIssueClosed}
          maxLength={1024}
          emptyMessage='Не задано'
          allowSubmitEmpty
          styleTextArea={descriptionFieldStyles}
          editIcon={smallPencil}
          editable={!!issue.allowedActions?.changeableFields?.description}
        />
      </div>
      {issueAnswer?.status === 'PENDING' && (
        <IssueOfficialReply
          officialReply={issueAnswer}
          onDelete={
            issue.assignees.some(
              ({ assigneeId }) => assigneeId === userInfo?.id,
            ) && //TODO Костыль, придумать как эту логику утащить на бэк
            issue.allowedActions?.changeStatus?.[
              api.IssueStatus.Enum.IN_PROCESS
            ] &&
            issue.status === api.IssueStatus.Enum.ON_REVIEW
              ? onDeleteOfficialReply
              : null
          }
          onAccept={
            issue.allowedActions?.changeStatus?.[api.IssueStatus.Enum.CLOSED]
              ? onAcceptOfficialReply
              : null
          }
          onReject={
            issue.allowedActions?.changeStatus?.[api.IssueStatus.Enum.CLOSED]
              ? onRejectOfficialReply
              : null
          }
          disabled={issuePending}
        />
      )}
      <div>
        <AttachmentTitlePanel>
          <AttachmentFieldName>Приложения</AttachmentFieldName>
          <AttachmentCount>{fileList?.length || 0}</AttachmentCount>
          {fileList?.length ? (
            <Button
              size='regular'
              color='tertiary'
              onClick={() => onCheckAll()}
            >
              {allChecked ? 'Снять выделение' : 'Выбрать всё'}
            </Button>
          ) : null}
          {!!issue.allowedActions?.file?.add && (
            <AttachIconsWrapper>
              <Tooltip
                title={isMaxCountFiles ? maxCountErrorMessage : 'Добавить файл'}
                styleContent={{
                  width: 'max-content',
                }}
                noMaxWidth
              >
                <AttachFile
                  variant='default'
                  disabled={issuePending || isMaxCountFiles || isIssueClosed}
                  onChange={onUploadFile}
                />
              </Tooltip>
              <SelectView
                isCompact={isCompact}
                onSelect={value => {
                  setIsCompact(value)
                  setSelectViewTypeToStorage(value)
                }}
              />
            </AttachIconsWrapper>
          )}
        </AttachmentTitlePanel>
        {fileList?.length !== 0 && !isCompact && (
          <div>
            <SimpleScrollbar
              isCompact={isHidden}
              compactHeight={155}
              height={305}
            >
              <Gallery
                disabled={issuePending}
                fileList={fileList || []}
                fileListChecked={checked as Record<number, boolean>}
                onCheckedFile={onCheckedFile}
                onDeleteFile={onDeleteFile}
                onDownloadFile={onDownloadFile}
                getImgSrc={file => getImageLink(file.id)}
                onOpenViewer={onOpenViewer}
                isDeletable={file =>
                  !!issue.allowedActions?.file?.delete?.some(
                    id => file.id === id,
                  )
                }
              />
            </SimpleScrollbar>
          </div>
        )}
        {fileList?.length !== 0 && isCompact && (
          <>
            <SimpleScrollbar
              isCompact={isHidden}
              compactHeight={211}
              height={476}
            >
              <AttachmentTable
                issueId={issue.id}
                columns={columns}
                setColumns={setColumns}
                fileList={fileList || []}
                tableService={attachmentTableService}
                sort={sort}
                setSort={setSort}
                onOpenViewer={onOpenViewer}
              />
            </SimpleScrollbar>
          </>
        )}
        {Number(fileList?.length) > initialCountFiles && (
          <ShowAllWrapper>
            <ShowAll
              hideButtonText='Свернуть'
              showButtonText='Развернуть'
              onSwitch={() => setIsHidden(prev => !prev)}
              isHidden={isHidden}
            />
            <DownloadButton
              size='regular'
              color='info'
              onClick={onDownloadFiles}
            >
              Скачать
            </DownloadButton>
          </ShowAllWrapper>
        )}
      </div>
      <div>
        <FieldName>Активность</FieldName>
        <Tabs
          onChangeTab={setTabIndex}
          activeTabIndex={tabIndex}
          headerStyles={{
            fontSize: '14px',
            height: '40px',
            gap: 19,
            marginTop: 'auto',
            marginBottom: '-1px',
            borderBottom: 'none',
          }}
          tabs={[
            {
              title: 'Комментарии',
              content: null,
            },
            {
              title: 'История',
              content: null,
            },
          ]}
        />

        {tabIndex === TabIndex.comment && (
          <Comments
            issue={issue}
            userInfo={userInfo}
            disabled={issue.status === api.IssueStatus.Enum.CLOSED}
            onUpdateIssueHandler={onUpdateIssueHandler}
          />
        )}
        {tabIndex === TabIndex.activity && (
          <ActivityWrapper>
            <EditIssuePopupHistoryChanges issue={issue} />
          </ActivityWrapper>
        )}
      </div>
    </>
  )
}
